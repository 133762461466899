<script>
export default {
    name:'Withdraw_Success',
    components: {
        Header: () => import('components/base/Header'),

        'mt-header':app.Mint.Header,
        'mt-button':app.Mint.Button,
    },
    data() {
        return {
            nowtime: "",

            header: {
                mainTitle: getWord(['withdraw3', 'information']),
            },
        };
    },
    methods: {
        left_click() {
            let hisNum = parseInt(window.history.length);
            if (hisNum == 1) {
                this.$router.push("/home");
            } else {
                this.$router.go(-1);
            }
        }
    },
    mounted() {
        var myDate = new Date();
        this.nowtime = myDate.toLocaleString(); // 获取日期与时间
    }
};
</script>
<template>
    <section id="withdraw-success" :class="_TEMPLATE">
        <div class="main">
            <template v-if="_TEMPLATE==='template-1' || _TEMPLATE==='template-2'">
                <mt-header :title="getWord('withdraw')" style="height:0.88rem;font-size: 0.35rem;">
                    <mt-button icon="back" slot="left" @click="left_click()"></mt-button>
                </mt-header>
                <div class="main_image"></div>
            </template>
            <template v-if="_TEMPLATE==='template-3'">
                <Header 
                    :mainTitle="header.mainTitle" 
                    :backButton=false 
                />
                <i class="icon icon-success"></i>
            </template>
            
            <span class="main_span1">{{ getWord('to_be_audit2') }}</span>
            <span class="main_span2">{{nowtime}}</span>
            <router-link to="/p">{{ getWord('done') }}</router-link>
        </div>
    </section>
</template>
<style scoped lang='scss' style="text/css">
#withdraw-success {
    background-color:#eeeeee;
    height: 100%;

    &.template-3 {

        .main {

            .main_span1 {
                font-size: .36rem;
                color: #666666;
            }

            .main_span2 {
                color: #B0B0B0;
                font-size: .26rem;
            }

            .icon {
                width: 2rem;
                height: 2rem;
                display: block;
                margin: 1rem auto .5rem;
                background-color: #eeeeee;
                border-radius: 100%;

                &.icon-success {
                    background-color: #F35959;
                    background-size: 70%;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-image: url('/static/assets/images/personal/icons/success-right.png');
                }
            }

            > a {
                background-color: #EC2829;
            }
        }
    }

    .myposition {
        background-color: rgba(239, 239, 239, 1);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
    }

    .main {

        .main_image {
            height: 1.46rem;
            width: 1.45rem;
            background-image: url('/static/assets/images2/user/cash/dztb.png');
            background-size: 100%;
            margin: 2.4rem 0 0 3rem;
            background-size: contain;
        }

        .main_span1 {
            display: block;
            font-size: 0.3rem;
            color: rgba(51, 51, 51, 1);
            margin-top: 0.4rem;
            text-align: center;
        }

        .main_span2 {
            display: block;
            font-size: 0.24rem;
            color: rgba(153, 153, 153, 1);
            margin-top: 0.1rem;
            text-align: center;
        }

        > a {
            font-size: .34rem;
            border-radius:7px;
            display: block;
            background-color: #26a2ff;
            width: 84%;
            padding:.2rem 0;
            color: #ffffff;
            margin:.5rem auto;
            text-align: center;
        }
    }
}
</style>
